import React from 'react';
import styles from './styles.module.css';

export default () => {
    const [consented, setConsented] = React.useState<boolean | undefined>(
        undefined,
    );

    const injectGA = () => {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-2S7WTM4KLC';
        script.type = 'text/javascript';
        script.async = true;
        script.onload = () => {
            // @ts-expect-error gtag
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                // @ts-expect-error gtag
                // eslint-disable-next-line prefer-rest-params
                dataLayer.push(arguments);
            }
            // @ts-expect-error gtag
            gtag('js', new Date());

            // @ts-expect-error gtag
            gtag('config', 'G-2S7WTM4KLC');
        };
        document.head.appendChild(script);
    };

    React.useEffect(() => {
        const hasCookies = document.cookie.length > 0;

        if (hasCookies) {
            injectGA();
        } else {
            setConsented(false);
        }
    }, []);

    if (consented || typeof consented === 'undefined') return null;

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <h3>Cookie consent</h3>
                <p>
                    {
                        'This website uses cookies to enhance your browsing experience. They help us understand how you interact with the content, including your preferences and search queries. The information collected is used to improve the relevance of the guidelines, ensuring you can find the information you need. By consenting to the use of cookies, you are supporting our ongoing efforts to provide a better experience for all users.'
                    }
                </p>
                <button
                    type="button"
                    onClick={() => {
                        injectGA();
                        setConsented(true);
                    }}
                >
                    Accept
                </button>
            </div>
        </div>
    );
};

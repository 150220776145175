import React from 'react';
import {createRoot} from 'react-dom/client';
import styles from './styles.module.css';
import Home from './Home/index';
import HamburgerSvg from './HamburgerSvg';
import Menu from './Menu';
import Search from './Search/index';
import RightItems from './RightItems';
import PasswordBanner from './PasswordBanner';
import CookiesConsent from './CookiesConsent';

type Items = {name: string; link: string; newTab: boolean}[];

const Header = ({items}: {items: Items}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerInner}>
                    <Home />
                    <Search />
                </div>
                <div className={styles.headerInner}>
                    {!open && <RightItems items={items} />}
                    {!open && (
                        <HamburgerSvg
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.advertisement}>
                <b>Public consultation open from 26th April to 31st May</b>
            </div>
            <Menu
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            />
            <PasswordBanner />
            <CookiesConsent />
        </>
    );
};

export default (items: Items) => {
    const domNode = document.getElementById('header');
    if (domNode) {
        const root = createRoot(domNode);
        root.render(<Header items={items} />);
    }
};
